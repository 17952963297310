import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { omit } from "lodash"
import axios from "axios";
import { CLASSIFIER_URL } from "../../../configs/constants"

export const IS_SMARTTAGS_ACTIVE = true;

export const FETCH_TAGS = 'FETCH_TAGS'
export const TOGGLE_TAGS_MODAL = 'TOGGLE_TAGS_MODAL'
export const SET_TRAIN_STATUS = "SET_TRAIN_STATUS";
export const ADD_TAG = "ADD_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const ARCHIVE_TAG = "ARCHIVE_TAG";
export const SET_SMART = "SET_SMART";
export const FLUSH_TAGS = "FLUSH_TAGS";


const getSmartTagDataByText = (text, companyId) => {
    return (dispatch, getState, { rcsdk, rcRPA }) => {
        return rcsdk.getSmartTagDatas().where({
            companyId: companyId,
            text: text
        }).then(resp => {
            return resp?.[0];
        })
    }
}

const getSmartTagDatasByTagId = (tagId, companyId) => {
    return (dispatch, getState, { rcsdk, rcRPA }) => {
        return rcsdk.getSmartTagDatas().where({
            companyId: companyId,
            tagIds: `tid:${tagId}`
        }).then(resp => {
            return {
                tagId: tagId,
                smartTagDatas: resp
            }
        })
    }
}


export const setParent = ({ tag, allTags = null }) => {
    return {
        ...tag,
        parent: allTags.find(el => el.id === tag.parentTagId)
    }
}

export const fetchTags = (companyId, ids = []) => {
    return (dispatch, getState, { rcsdk, rcRPA }) => {
        dispatch({ type: FETCH_TAGS, status: "pending" })

        let where = companyId ? { companyId } : {}
        if (ids.length > 0) where = {
            'id': {
                inq: ids
            }
        }

        return rcsdk.getTags()
            .where(where)
            .include({ "relation": "parentTag" })
            .then(resp => {
                let formattedTags = resp.map(el => {
                    if (!el?.parentTagId) return el;
                    return setParent({ tag: el, allTags: resp })
                });

                let smartTagDatasPromises = [];
                formattedTags.forEach(formattedTag => {
                    if (formattedTag.isSmart) {
                        smartTagDatasPromises.push(
                            dispatch(getSmartTagDatasByTagId(formattedTag.id))
                        )
                    }
                })

                return Promise.all(smartTagDatasPromises).then(smartTagDatas => {
                    formattedTags = formattedTags.map(formattedTag => {
                        let tagSmartTagDatas = smartTagDatas.find(smartTagData => smartTagData.tagId === formattedTag.id)?.smartTagDatas || [];
                        return {
                            ...formattedTag,
                            sentences: tagSmartTagDatas.map(tagSmartTagData => {
                                return { id: tagSmartTagData.id, text: tagSmartTagData.text }
                            })
                        }
                    })

                    dispatch({
                        type: FETCH_TAGS,
                        status: "success",
                        tags: formattedTags
                    })
                    return formattedTags
                })

            }).catch(err => {
                dispatch({ type: FETCH_TAGS, status: "error", err: err })
            });
    }
}

const addSmartTagData = ({ text, tagId, companyId }) => {
    return (dispatch, getState, { rcsdk }) => {
        return dispatch(getSmartTagDataByText(text, companyId)).then(tagData => {
            if(tagData){
                if(!tagData.tagIds.includes(`tid:${tagId}`)){
                    return rcsdk.updateSmartTagData(tagData.id, {tagIds: [...tagData.tagIds, tagId], companyId})
                } else {
                    return tagData;
                }
            } else {
                return rcsdk.createSmartTagData({
                    companyId: companyId,
                    tagIds: [tagId],
                    text: text
                })
            }
        })
    }
}

const deleteSmartTagData = ({ text, tagId, companyId }) => {
    return (dispatch, getState, { rcsdk }) => {
        return dispatch(getSmartTagDataByText(text, companyId)).then(tagData => {
            if (tagData) {
                if (tagData.tagIds.length === 1 && tagData.tagIds.includes(`tid:${tagId}`)) {
                    return rcsdk.deleteSmartTagData(tagData.id)
                } else {
                    return rcsdk.updateSmartTagData(tagData.id, { tagIds: [...tagData.tagIds.filter(el => el !== `tid:${tagId}`)] })
                }
            }
        });
    }
}

const formatTag = (tag) => {
    return { ...omit(tag, ["sentences"]), color: tag.color.trim() }
}

export const addTag = (tag, showToast = true, companyId) => {
    return (dispatch, getState, { rcsdk }) => {
        tag = { ...tag, companyId };
        dispatch({ type: ADD_TAG, status: "pending" });
        return rcsdk.createTag(formatTag(tag))
            .then((resp) => {
                let sentencesPromises = [];
                if (resp.isSmart && tag?.sentences) {
                    tag.sentences.filter(el => !el?.delete).forEach(sentence => {
                        if (sentence.text.trim() !== "") {
                            sentencesPromises.push(
                                dispatch(addSmartTagData({
                                    text: sentence.text.trim(),
                                    tagId: resp.id,
                                    companyId
                                }))
                            );
                        }
                    })
                }

                return Promise.all(sentencesPromises).then(sentencesResp => {
                    let formattedTag = {
                        ...resp,
                        sentences: sentencesResp.map(sentenceResp => {
                            return sentenceResp?.id ? { id: sentenceResp.id, text: sentenceResp.text } : null
                        }).filter(el => el)
                    };

                    dispatch({
                        type: ADD_TAG,
                        tag: formattedTag,
                        status: "success",
                        successToast: showToast ? {
                            type: "ADD",
                            message: resp.isSmart ? <FormattedMessage id="smartTags.toast.add" defaultMessage="Smart tag added" /> : <FormattedMessage id="tags.toast.add" defaultMessage="Tag added" />
                        } : null
                    });
                    return formattedTag;
                })

            }).catch(err => {
                console.log("🚀 ~ file: index.js ~ line 179 ~ .then ~ err", err)
                dispatch({ type: ADD_TAG, status: "error" });
            });
    }
}

export const updateTag = (tag, showToast = true, companyId) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: UPDATE_TAG, status: "pending" });
        return rcsdk.updateTag(tag.id, formatTag(tag))
        .then((resp) => {

            let sentencesPromises = [];
            if(resp.isSmart && tag?.sentences){
                tag.sentences.forEach(sentence => {
                    if(sentence.delete){
                        dispatch(deleteSmartTagData({
                            text: sentence.text.trim(),
                            tagId: resp.id,
                            companyId: companyId
                        }))
                    } else {
                        if(sentence.text.trim() !== ""){
                            sentencesPromises.push(
                                dispatch(addSmartTagData({
                                    text: sentence.text.trim(),
                                    tagId: resp.id,
                                    companyId: companyId
                                }))
                            );
                        }
                    }})
                }

                return Promise.all(sentencesPromises).then(sentencesResp => {
                    let formattedTag = {
                        ...resp,
                        sentences: sentencesResp.map(sentenceResp => {
                            return sentenceResp?.id ? { id: sentenceResp.id, text: sentenceResp.text } : null
                        }).filter(el => el)
                    };

                    dispatch({
                        type: UPDATE_TAG,
                        tag: formattedTag,
                        status: "success",
                        successToast: showToast ? {
                            type: "UPDATE",
                            message: <FormattedMessage id="tags.toast.edit" defaultMessage="Tag edited" />
                        } : null
                    });
                    return formattedTag;
                })

            }).catch(err => {
                dispatch({ type: UPDATE_TAG, status: "error" });
            });
    }
}

export const archiveTag = ({ tag, archived, showToast = true }) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: ARCHIVE_TAG, status: "pending" });

        let childPromises = []
        if (archived && !tag.parentTagId) {
            let childTags = getState().tags.tags.filter(el => el.parentTagId === tag.id && !el.archived);
            childPromises = childTags.map(childTag => {
                return rcsdk.updateTag(childTag.id, { archived: true })
            });
        }

        return Promise.all(childPromises).then(childTags => {
            childTags.forEach(childTag => {
                dispatch({ type: ARCHIVE_TAG, tagId: childTag.id, archived: archived });
            })

            return rcsdk.updateTag(tag.id, { archived: archived })
                .then((tag) => {
                    dispatch({
                        type:
                            ARCHIVE_TAG,
                        tagId: tag.id,
                        archived: archived,
                        status: "success",
                        successToast: showToast ? {
                            type: archived ? "ARCHIVE" : "UNARCHIVE",
                            message: archived
                                ? <FormattedMessage id="tags.toast.archived" defaultMessage="Tag archived" />
                                : <FormattedMessage id="tags.toast.unarchived" defaultMessage="Tag unarchived" />
                        } : null
                    });
                    return tag;
                }).catch(err => {
                    dispatch({ type: ARCHIVE_TAG, status: "error" });
                });
        });

    }
}

export const makeCategorySmart = (cat) => {
    return (dispatch, getState, { rcsdk }) => {
        if (!cat.parentTagId) {
            let childTags = getState().tags.tags.filter(el => el.parentTagId === cat.id);
            let childPromises = childTags.map(childTag => {
                return rcsdk.updateTag(childTag.id, { isSmart: true })
            });

            return Promise.all(childPromises).then(childTags => {
                childTags.forEach(childTag => {
                    dispatch({ type: SET_SMART, tagId: childTag.id });
                })

                return rcsdk.updateTag(cat.id, { isSmart: true })
                    .then((resp) => {
                        dispatch({ type: SET_SMART, tagId: resp.id });
                        return resp;
                    }).catch(err => { });
            });
        }
        return false;
    }
}

export const trainTags = ({ classifier, companyId }) => {
    return (dispatch, getState, { rcsdk }) => {
        dispatch({ type: SET_TRAIN_STATUS, status: "pending" });

        axios.post(CLASSIFIER_URL + "/train", {
            companyId: companyId,
            member: {
                memberId: getState().user.rcuser.rcId,
                firstName: getState().user.rcuser.firstName,
                lastName: getState().user.rcuser.lastName,
            },
            classifier: {
                id: classifier.id,
                label: classifier.name
            }
        }).then(resp => {
            dispatch({ type: SET_TRAIN_STATUS, status: "success" });
            toast.success(<FormattedMessage id="tag.training.progress" defaultMessage="Training in progress…" />)
        }).catch(function (error) {
            toast.error(error.message)
            dispatch({ type: SET_TRAIN_STATUS, status: "error" });
        });
    }
}


const populateCatWithChildTags = (cat, allTags) => {
    return { ...cat, tags: allTags.filter(el => el.parentTagId === cat.id) }
}

const selectTagCategories = (tags, smart) => {
    let tagCategories = [...tags.filter(el => !el?.parentTagId)];
    if (smart !== "all") {
        if (smart) {
            tagCategories = tagCategories.filter(el => el.isSmart)
        } else {
            tagCategories = tagCategories.filter(el => !el.isSmart)
        }
    }
    return tagCategories.map(el => populateCatWithChildTags(el, tags));
}


export { selectTagCategories }
