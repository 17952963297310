import axios from "axios";
import { RPA_URL } from "../../../configs/constants";

export const FETCH_RULES_PENDING = "FETCH_RULES_PENDING";
export const FETCH_RULES_SUCCESS = "FETCH_RULES_SUCCESS";
export const FETCH_RULES_ERROR = "FETCH_RULES_ERROR";
export const CREATE_RULE_PENDING = "CREATE_RULE_PENDING";
export const CREATE_RULE_SUCCESS = "CREATE_RULE_SUCCESS";
export const CREATE_RULE_ERROR = "CREATE_RULE_ERROR";
export const UPDATE_RULE_PENDING = "UPDATE_RULE_PENDING";
export const UPDATE_RULE_SUCCESS = "UPDATE_RULE_SUCCESS";
export const UPDATE_RULE_ERROR = "UPDATE_RULE_ERROR";
export const DELETE_RULE_PENDING = "DELETE_RULE_PENDING";
export const DELETE_RULE_SUCCESS = "DELETE_RULE_SUCCESS";
export const FETCH_QUICK_ACTIONS_PENDING = "FETCH_QUICK_ACTIONS_PENDING";
export const FETCH_QUICK_ACTIONS_SUCCESS = "FETCH_QUICK_ACTIONS_SUCCESS";
export const FETCH_QUICK_ACTIONS_ERROR = "FETCH_QUICK_ACTIONS_ERROR";
export const FETCH_RULE_STEPS_CONDITIONS_PENDING =
  "FETCH_RULE_STEPS_CONDITIONS_PENDING";
export const FETCH_RULE_STEPS_CONDITIONS_SUCCESS =
  "FETCH_RULE_STEPS_CONDITIONS_SUCCESS";
export const FETCH_RULE_STEPS_CONDITIONS_ERROR =
  "FETCH_RULE_STEPS_CONDITIONS_ERROR";
export const FLUSH_DELETE = "FLUSH_DELETE";
export const FLUSH_RULE = "FLUSH_RULE";
export const FLUSH_CREATE = "FLUSH_CREATE";
export const FLUSH_UPDATE = "FLUSH_UPDATE";
export const FETCH_SOURCES_FILTERS_PENDING = "FETCH_SOURCES_FILTERS_PENDING";
export const FETCH_SOURCES_FILTERS_SUCCESS = "FETCH_SOURCES_FILTERS_SUCCESS";
export const FETCH_SOURCES_FILTERS_ERROR = "FETCH_SOURCES_FILTERS_ERROR";

export const FLUSH_FETCH_RULES = "FLUSH_FETCH_RULES";

export const SET_EXPERT_MODE = "SET_EXPERT_MODE";
export const FLUSH_RULES = "FLUSH_RULES";

export const fetchSourcesAndFilters = () => {
  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: FETCH_SOURCES_FILTERS_PENDING });
    return Promise.all([
      axios.get(`${RPA_URL}/rules/sources`),
      axios.get(`${RPA_URL}/rules/filters`),
    ])
      .then((res) => {
        dispatch({ type: FETCH_SOURCES_FILTERS_SUCCESS, data: res });
      })
      .catch((err) =>
        dispatch({ type: FETCH_SOURCES_FILTERS_ERROR, error: err })
      );
  };
};

export const fetchRules = (searchTerm, companyId) => {
  const search = () => {
    if (searchTerm.search.length === 0) return;
    switch (searchTerm.field) {
      case "":
        return;
      case "keyword":
        if (companyId)
          return {
            and: [
              { name: { like: `.*${searchTerm.search}.*`, options: "i" } },
              { companyId: { eq: companyId } },
            ],
          };
        else
          return { name: { like: `.*${searchTerm.search}.*`, options: "i" } };
      case "companyId":
        return { companyId: searchTerm.search };
      case "id":
        return { id: searchTerm.search };
      case "ids":
        return {
          id: {
            inq: searchTerm.search,
          },
        };
      default:
        break;
    }
  };

  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: FETCH_RULES_PENDING });
    return rcsdk
      .getRules()
      .where(searchTerm && search())
      .then((rules) => {
        dispatch({ type: FETCH_RULES_SUCCESS, rules: rules });
      })
      .catch((err) => {
        dispatch({ type: FETCH_RULES_ERROR, error: err });
      });
  };
};

const createRuleConditions = async (rcsdk, conditions, companyId) => {
  var conditionPromise = [];
  for (let index = 0; index < conditions.length; index++) {
    const condition = conditions[index];

    let tmpCondition;
    if (condition.filter === "OR" || condition.filter === "AND") {
      var subConditions = await createRuleConditions(
        rcsdk,
        condition.rows,
        companyId
      );
      tmpCondition = {
        source: "",
        value: "",
        filter: condition.filter,
        conditions: subConditions,
        companyId,
      };
    } else {
      tmpCondition = { ...condition, ...{ companyId } };
      if (tmpCondition.subSource !== "")
        tmpCondition.source =
          tmpCondition.source + "." + tmpCondition.subSource;
      delete tmpCondition.subSource;
    }
    // tmpCondition["isTest"] = true;
    conditionPromise.push(rcsdk.createRuleCondition(tmpCondition));
  }

  return Promise.all(conditionPromise).then((results) =>
    results.map((res) => res.id)
  );
};

export const createRule = (
  sequence,
  companyId,
  name,
  description,
  quickActions
) => {
  return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: CREATE_RULE_PENDING });

    var rule = {
      name,
      description,
      companyId,
      sequence: [],
      // isTest: true,
    };

    for (var i = 0; i < sequence.length; i++) {
      var stepC = {
        type: sequence[i].conditionType,
        companyId,
        conditions: [],
        // isTest: true,
      };
      if (sequence[i].conditionType !== "ELSE") {
        stepC.conditions = await createRuleConditions(
          rcsdk,
          sequence[i].rows,
          companyId
        );
        var step = await rcsdk.createRuleStep(stepC);
        rule.sequence.push({
          step: i,
          stepId: step.id,
          then: sequence[i].quickActions,
        });
      } else {
        rule.sequence.push({
          step: i,
          stepId: null,
          then: sequence[i].quickActions,
        });
      }
    }

    return rcsdk
      .createRule(rule)
      .then((response) => {
        dispatch({ type: CREATE_RULE_SUCCESS, rule: response });
      })
      .catch((err) => {
        dispatch({ type: CREATE_RULE_ERROR, error: err });
      });
  };
};

const deleteRuleConditions = (rcsdk, conditions) => {
  for (let index = 0; index < conditions.length; index++) {
    const conditionId = conditions[index];
    rcsdk.getRuleCondition(conditionId).then((condition) => {
      if (condition.filter === "OR" || condition.filter === "AND") {
        deleteRuleConditions(rcsdk, condition.conditions);
      }
      rcsdk.deleteRuleCondition(conditionId);
    });
  }
};

const deleteRuleSequence = (rcsdk, sequence) => {
  for (var x = 0; x < sequence.length; x++) {
    const stepId = sequence[x].stepId;
    if (stepId) {
      rcsdk.getRuleStep(stepId).then((step) => {
        const conditions = step.conditions;
        deleteRuleConditions(rcsdk, conditions);
        rcsdk.deleteRuleStep(stepId);
      });
    }
  }
};

export const deleteRule = (ruleId, sequence) => {
  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: DELETE_RULE_PENDING });
    deleteRuleSequence(rcsdk, sequence);
    rcsdk
      .deleteRule(ruleId)
      .then((res) => dispatch({ type: DELETE_RULE_SUCCESS, ruleId: ruleId }));
  };
};

export const updateRule = (
  sequence,
  companyId,
  name,
  description,
  ruleId,
  oldSequence
) => {
  return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: UPDATE_RULE_PENDING });

    deleteRuleSequence(rcsdk, oldSequence);

    var rule = {
      name,
      description,
      sequence: [],
    };

    for (var i = 0; i < sequence.length; i++) {
      var stepC = {
        type: sequence[i].conditionType,
        companyId,
        conditions: [],
      };
      if (sequence[i].conditionType !== "ELSE") {
        stepC.conditions = await createRuleConditions(
          rcsdk,
          sequence[i].rows,
          companyId
        );
        var step = await rcsdk.createRuleStep(stepC);
        rule.sequence.push({
          step: i,
          stepId: step.id,
          then: sequence[i].quickActions,
        });
      } else {
        rule.sequence.push({
          step: i,
          stepId: null,
          then: sequence[i].quickActions,
        });
      }
    }

    return rcsdk
      .updateRule(ruleId, rule)
      .then((response) => {
        dispatch({ type: UPDATE_RULE_SUCCESS, rule: response });
      })
      .catch((err) => {
        dispatch({ type: UPDATE_RULE_ERROR, error: err });
      });
  };
};

export const getQuickActionLibraries = (companyId) => {
  return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: FETCH_QUICK_ACTIONS_PENDING });
    return (
      rcsdk
        .getQuickActionLibraries()
        .where({ companyId: { inq: [companyId, "*"] } })
        // .where({companyId})
        .then((response) => {
          dispatch({
            type: FETCH_QUICK_ACTIONS_SUCCESS,
            quickActions: response,
          });
        })
        .catch((err) => {
          dispatch({ type: FETCH_QUICK_ACTIONS_ERROR, error: err });
        })
    );
  };
};

const getRuleConditions = async (rcsdk, conditions) => {
  var conditionPromise = [];
  for (let index = 0; index < conditions.length; index++) {
    const condition = conditions[index];
    conditionPromise.push(
      rcsdk.getRuleCondition(condition).then(async (response) => {
        var obj = {
          filter: response.filter,
          source: response.source,
          value: response.value,
        };
        if (response.conditions.length > 0)
          obj["rows"] = await getRuleConditions(rcsdk, response.conditions);
        return obj;
      })
    );
  }

  return Promise.all(conditionPromise).then((results) => results);
};

export const getRuleStepsAndConditions = (idsArray) => {
  return async (dispatch, getState, { rcsdk, rcAlgolia }) => {
    dispatch({ type: FETCH_RULE_STEPS_CONDITIONS_PENDING });
    rcsdk
      .getRuleSteps()
      .where({ id: { inq: idsArray } })
      .then(async (response) => {
        const steps = [];
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          var rows = await getRuleConditions(rcsdk, element.conditions);
          steps.push({
            step: element,
            conditions: rows,
            index: idsArray.indexOf(element.id),
          });
        }
        if (steps.length === response.length)
          dispatch({ type: FETCH_RULE_STEPS_CONDITIONS_SUCCESS, steps: steps });
      })
      .catch((err) => {
        dispatch({ type: FETCH_RULE_STEPS_CONDITIONS_ERROR, error: err });
      });
  };
};

export const fetchRule = (id) => {
  return (dispatch, getState, { rcsdk, rcAlgolia }) => {
    return rcsdk
      .getRule(id)
      .then((response) => {
        return response;
      })
      .catch((err) => {});
  };
};
