import {
    FETCH_CHANNELS_PENDING,
    FETCH_CHANNELS_SUCCESS,
    FETCH_CHANNELS_ERROR,
    ADD_CHANNEL,
    FLUSH_CHANNELS,
    UPDATE_CHANNEL,
    DELETE_CHANNEL,
    FETCH_CHANNEL_CONNECTIONS_SUCCESS,
    FETCH_CHANNEL_CONNECTIONS_ERROR,
    UPDATE_CHANNEL_CONNECTION,
    ADD_CHANNEL_CONNECTION,
    DELETE_CHANNEL_CONNECTION,
    FETCH_CHANNEL_CONNECTIONS_BY_CHANNEL_ID,
    FLUSH_FETCH_CHANNELS
} from '../../actions/channels';

const initialState = {
    pending: false,
    channels: [],
    fetchChannelsStatus: null,
    channelConnections: [],
    channelConnectionsByChannelId: [],
    updateStatus: false,
    error: null,
}

const channelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHANNEL_CONNECTIONS_BY_CHANNEL_ID:
            return {
                ...state,
                channelConnectionsByChannelId: action.channelConnectionsByChannelId
            }
        case FETCH_CHANNELS_PENDING:
            return {
                ...state,
                pending: true
            }
        case FLUSH_FETCH_CHANNELS:
            return {
                ...state,
                fetchChannelsStatus: null,
            }
        case FETCH_CHANNELS_SUCCESS:
            return {
                ...state,
                fetchChannelsStatus: "success",
                pending: false,
                channels: action.channels
            }
        case FETCH_CHANNELS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case ADD_CHANNEL:
            return {
                ...state,
                channels: [
                    ...state.channels,
                    action.channel
                ]
            }

        case FLUSH_CHANNELS:
            return {
                ...state,
                channels: []
            }

        case UPDATE_CHANNEL:
            const targetedChannel = action.status !== "pending" && state.channels.find(chan => chan.id === action.channel.id)
            return {
                ...state,
                updateStatus: action.status === "pending",
                channels: action.status === "success" ? [
                    ...state.channels.filter(chan => chan.id !== action.channel.id),
                    { ...action.channel, company: targetedChannel.company, acd: action?.acd ? { ...action.acd } : {} }
                ] : state.channels
            }


        case DELETE_CHANNEL:
            return {
                ...state,
                channels: [...state.channels.filter(chan => chan.id !== action.channelId)]
            }

        case FETCH_CHANNEL_CONNECTIONS_SUCCESS:
            return {
                ...state,
                pending: false,
                channelConnections: action.channelConnections
            }

        case FETCH_CHANNEL_CONNECTIONS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }

        case ADD_CHANNEL_CONNECTION:
            return {
                ...state,
                channelConnections: [
                    ...state.channelConnections,
                    action.channelConnection
                ]
            }

        case UPDATE_CHANNEL_CONNECTION:
            return {
                ...state,
                channelConnections: [
                    ...state.channelConnections.map(chanCon => {
                        if (chanCon.id !== action.channelConnection.id) return { ...chanCon }
                        return { ...action.channelConnection }
                    })
                ]
            }

        case DELETE_CHANNEL_CONNECTION:
            return {
                ...state,
                channelConnections: [
                    ...state.channelConnections.filter(chanCon => chanCon.id !== action.channelConnectionId)
                ]
            }

        default:
            return state;
    }
}
export default channelsReducer;