export const BASE_URL =
    process.env?.REACT_APP_RC_BASE_URL || "https://bo.reecall.co";
export const CORE_URL =
    process.env?.REACT_APP_RC_CONNECTORS || "https://connectors.reecall.co";
export const RPA_URL =
    process.env?.REACT_APP_RC_RPA_BASE || "https://rpa.reecall.co";
export const API_BASE =
    process.env?.REACT_APP_RC_API_BASE || "https://api.reecall.cloud";
export const COLLECTION_URL =
    process.env?.REACT_APP_RC_COLLECTION || "https://collections.reecall.io";
export const EXPORT_URL =
    process.env?.REACT_APP_RC_EXPORT || "https://export.reecall.io";
export const BILLING_URL =
    process.env?.REACT_APP_RC_BILLING || "https://billing.reecall.io";
export const NLU_URL =
    process.env?.REACT_APP_RC_NLU_MODEL_STORE || "https://nlumodelstore.reecall.io";
export const MSNLU_URL =
    process.env?.REACT_APP_RC_MSNLU || "https://msnlu.reecall.io";
export const FAQ_URL =
    process.env?.REACT_APP_RC_FAQ || "https://faq.reecall.io";
export const HOOK_URL =
    process.env?.REACT_APP_RC_HOOKS || "https://hooks.reecall.io";
export const INGESTION_URL =
    process.env?.REACT_APP_RC_INGESTION || "https://ingestion.reecall.io";
export const TWILIO_USERNAME =
    process.env?.REACT_APP_TWILIO_USERNAME || "AC06581df421ee1ed5c1e486fb0a8c7acc";
export const TWILIO_PASSWORD =
    process.env?.REACT_APP_TWILIO_PASSWORD || "3846d7600b700c044d5b338183b16873";

////////////// NOT USED IN BACK_OFFICE YET //////////////

export const CLASSIFIER_URL =
    process.env?.REACT_APP_CLASSIFIER_URL || "https://classifier.reecall.io";
export const ACD_URL =
    process.env?.REACT_APP_ACD_URL || "https://acd.reecall.io";
export const PHONE_MANAGEMENT_URL =
    process.env?.REACT_APP_RC_PHONE_MANAGMENT || "https://phone.reecall.io";
export const SEARCH_URL =
    process.env?.REACT_APP_RC_SEARCH || "https://search.reecall.io/api";
