import React from "react"
import axios from "axios"
import { FormattedMessage } from "react-intl";
import { RPA_URL } from "../../../configs/constants"

export const FETCH_INTEGRATIONS_PENDING = 'FETCH_INTEGRATIONS_PENDING';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_ERROR = 'FETCH_INTEGRATIONS_ERROR';
export const FLUSH_INTEGRATIONS = 'FLUSH_INTEGRATIONS';

export const UPDATE_INTEGRATION_CONNECTION = "UPDATE_INTEGRATION_CONNECTION";

export const FETCH_INTEGRATION_CONNECTIONS_PENDING = 'FETCH_INTEGRATION_CONNECTIONS_PENDING';
export const FETCH_INTEGRATION_CONNECTIONS_SUCCESS = 'FETCH_INTEGRATION_CONNECTIONS_SUCCESS';
export const FETCH_INTEGRATION_CONNECTIONS_ERROR = 'FETCH_INTEGRATION_CONNECTIONS_ERROR';

export const CREATE_INTEGRATION_CONNECTION = "CREATE_INTEGRATION_CONNECTION";
export const DELETE_INTEGRATION_CONNECTION = "DELETE_INTEGRATION_CONNECTION";
export const FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID = "FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID";
export const FLUSH_FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID = "FLUSH_FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID";

export const SET_SHOW_INTEGRATION_CTA_MODAL = "SET_SHOW_INTEGRATION_CTA_MODAL";
export const SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL = "SET_SHOW_INTEGRATION_DETAIL_CTA_MODAL";

export const UPDATE_LIST_AFTER_DELETE = "UPDATE_LIST_AFTER_DELETE";

function fetchIntegrationsPending() {
    return {
        type: FETCH_INTEGRATIONS_PENDING
    }
}


function fetchIntegrationsSuccess(integrations) {
    return {
        type: FETCH_INTEGRATIONS_SUCCESS,
        integrations: integrations
    }
}

function fetchIntegrationsError(error) {
    return {
        type: FETCH_INTEGRATIONS_ERROR,
        error: error
    }
}

export const fetchIntegrations = (companyId = null) => {

    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let where = {};
        if (companyId) {
            where = { "or": [{ "companyId": companyId }, { "companyId": "*" }] }
            // where = { "companyId": companyId }
        }

        dispatch(fetchIntegrationsPending());
        return rcsdk.getIntegrations()
            .include(['quickActions'])
            .where(where)
            .then(response => {
                dispatch(fetchIntegrationsSuccess(response));
                return dispatch(fetchIntegrationConnections(companyId));
            }).catch(err => {
                dispatch(fetchIntegrationsError(err.message));
            })
    }
}

export function flushIntegrations() {
    return {
        type: FLUSH_INTEGRATIONS
    }
}


function fetchIntegrationConnectionsSuccess(integrationConnections) {
    return {
        type: FETCH_INTEGRATION_CONNECTIONS_SUCCESS,
        integrationConnections: integrationConnections
    }
}

function fetchIntegrationConnectionsError(error) {
    return {
        type: FETCH_INTEGRATION_CONNECTIONS_ERROR,
        error: error
    }
}

export const fetchIntegrationConnections = (companyId = null) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchIntegrationsPending());
        let where = {};
        if (companyId) {
            where = { "companyId": companyId }
        }

        return rcsdk.getIntegrationConnections().where(where)
            .then(response => {
                return dispatch(fetchIntegrationConnectionsSuccess(response));
            }).catch(err => {
                dispatch(fetchIntegrationConnectionsError(err.message));
            })
    }
}

export const fetchIntegrationConnectionsWithIntegrations = (companyId = null) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        let where = {};
        if (companyId) {
            where = { "companyId": companyId }
        }
        return rcsdk.getIntegrationConnections().include(["integration"]).where(where)
            .then(response => {
                return response;
            }).catch(err => {
                console.log(err.message);
            })
    }
}

export const fetchIntegrationConnectionsByCompanyId = (companyId = null) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID, status: "pending" });

        let where = {};
        if (companyId) {
            where = { companyId }
            // where = {  "or": [{ "companyId": companyId }, { "id": "5fae9fc77da43200088a051c" }] } 
        }

        return rcsdk.getIntegrationConnections().include(["integration"]).where(where)
            .then(response => {
                return dispatch({ type: FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID, status: "success", integrationConnections: response });
            }).catch(err => {
                dispatch({ type: FETCH_INTEGRATIONS_CONNECTIONS_BY_COMPANY_ID, status: "error", error: err.message });
            })
    }
}

export const installIntegration = ({provider, integrationConnectionId}) => {
    return (dispatch, getState, {rcsdk}) => {
        return axios({
            method: "POST",
            url: `${RPA_URL}/integration/${provider}/install`,
            data: { 
                companyId: getState().company.company.id,
                integrationConnectionId: integrationConnectionId
            },
            headers: {
                "x-reecall-source": "APP",
                "x-reecall-sourceId": getState().user.rcuser.rcId,
                "x-reecall-company": getState().company.company.id
            }
        })
    }
}

export const createIntegrationConnection = ({ provider, ...rest }) => {
    return (dispatch, getState, { rcsdk }) => {

        let datas = {
            "integrationId": getState().integrations.integrations.find(el => el.key === provider)?.id,
            "companyId": getState().company.company.id,
            "data": {
                ...rest
            }
        }

        if (!datas.integrationId) {
            console.error(`integration with key "${provider}" is not in database`);
            return false;
        }

        return rcsdk.createIntegrationConnection(datas)
            .then(response => {

                return dispatch(installIntegration({ provider: provider, integrationConnectionId: response.id })).then(resp => {
                    dispatch({
                        type: CREATE_INTEGRATION_CONNECTION,
                        integrationConnection: response,
                        successToast: {
                            type: "ADD",
                            message: <FormattedMessage id="integrations.toast.add" defaultMessage="Integration added successfully" />
                        }
                    })

                    return { ...resp.data, "integrationName": getState().integrations.integrations.find(el => el.key === provider)?.name };
                })

            }).catch(err => {
                dispatch(fetchIntegrationConnectionsError(err.message));
            })
    }
}


export const deleteIntegrationConnection = ({ provider, companyId, integrationId, integrationConnectionId }) => {
    return (dispatch, getState, { rcsdk }) => {

        if (!integrationId || !integrationConnectionId) return false;

        const onSuccess = () => {
            dispatch({
                type: DELETE_INTEGRATION_CONNECTION,
                integrationConnectionId: integrationConnectionId,
                integrationId: integrationId,
                successToast: {
                    type: "DELETE",
                    message: <FormattedMessage id="integrations.toast.delete" defaultMessage="Integration deleted successfully" />
                }
            })
        }
        return rcsdk.deleteIntegrationConnection(integrationConnectionId)
            .then(response => {
                return onSuccess()
            }).catch(err => {
                if (err.error?.response?.status !== 400) {
                    dispatch(fetchIntegrationConnectionsError(err.message));
                } else {
                    onSuccess()
                }
            })
    }
}


export const executeQuickAction = ({ quickAction, context }) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return null;
    }
}

export const getIntegrationsRPA = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia, rcRPA }) => {
        return rcRPA.getIntegrations();
    }
}
export const getIntegrationRPA = (integrationKey) => {
    return (dispatch, getState, { rcsdk, rcAlgolia, rcRPA }) => {
        return rcRPA.getIntegration(integrationKey);
    }
}

export const getIntegrationConfigByIntergrationConnectionId = (integrationConnectionId) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.getIntegrationConfigurations()
            .where({ "integrationConnectionId": integrationConnectionId })
            .then(response => {
                return response;
            }).catch(err => {
                console.log(err, "err");
            })
    }
}

export const getIntegrationConfigFromRPA = ({ provider }) => {
    return (dispatch, getState, { rcsdk }) => {
        return axios({
            method: "GET",
            url: `${RPA_URL}/integrations/${provider}`,
        }).then(resp => {
            return resp.data.integration;
        })
    }
}

export const uninstallIntegration = ({ provider, companyId, integrationId, integrationConnectionId }) => {
    return (dispatch, getState, { rcsdk }) => {

        if (!integrationId || !integrationConnectionId) return false;

        return axios({
            method: "POST",
            url: `${RPA_URL}/integration/${provider}/uninstall`,
            data: {
                companyId: companyId,
                integrationConnectionId: integrationConnectionId
            }
        }).then((res) => {
            return dispatch(deleteIntegrationConnection({ provider, companyId, integrationId, integrationConnectionId }));
        })
    }
}

export const updateIntegrationConnection = (id, data) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.updateIntegrationConnection(id, data)
            .then(response => {
                dispatch({
                    type: UPDATE_INTEGRATION_CONNECTION,
                    integrationConnection: response
                });
            }).catch(err => {
                dispatch(fetchIntegrationConnectionsError(err.message));
            })
    }
}

export const updateIntegrationConfiguration = (id, datas) => {
    return (dispatch, getState, { rcsdk }) => {
        return rcsdk.updateIntegrationConfiguration(id, datas).then(() => {
            dispatch({
                type: "SHOW_INTEGRATION_SUCCESS_TOAST",
                successToast: {
                    type: "UPDATE",
                    message: <FormattedMessage id="integrations.toast.updateSettings" defaultMessage="Integration settings updated successfully" />
                }
            })
        })
    }
}