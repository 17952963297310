import React from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import { RPA_URL } from './constants'

const RPA_URLs = [
    { label: "Production", value: RPA_URL },
    { label: "Alexis", value: "https://am-rpa.eu.ngrok.io" },
    { label: "Mathieu", value: "https://rcrpa.eu.ngrok.io" },
    { label: "Bastien", value: "https://bn-rpa.eu.ngrok.io" },
]

export {RPA_URLs}

const QuickActionsTitleMessages = defineMessages({
    "quickAction.title.Request": {
        id: "quickAction.title.Request", 
        defaultMessage: "Request"
    },
    "quickAction.title.Close ticket": {
        id: "quickAction.title.Close ticket", 
        defaultMessage: "Close ticket"
	},
    "quickAction.title.Get User by Phonenumber": {
        id: "quickAction.title.Get User by Phonenumber", 
        defaultMessage: "Get User by Phonenumber"
	},
    "quickAction.title.Get Order Details by OrderId": {
        id: "quickAction.title.Get Order Details by OrderId", 
        defaultMessage: "Get Order Details by OrderId"
	},
    "quickAction.title.Release User Phone": {
        id: "quickAction.title.Release User Phone", 
        defaultMessage: "Release User Phone"
	},
});


const QuickActionsDescMessages = defineMessages({
    "quickAction.desc.Close the ticket": {
        id: "quickAction.desc.Close the ticket", 
        defaultMessage: "Close the ticket"
    },
    "quickAction.desc.Close ticket": {
        id: "quickAction.desc.Close ticket", 
        defaultMessage: "Close ticket"
	},
    "quickAction.desc.find any customer by phone number": {
        id: "quickAction.desc.find any customer by phone number", 
        defaultMessage: "find any customer by phone number"
	},
    "quickAction.desc.Fetch order informations": {
        id: "quickAction.desc.Fetch order informations", 
        defaultMessage: "Fetch order informations"
	}
});

export { QuickActionsTitleMessages, QuickActionsDescMessages }

export const sortMenu = [
	{
		"label": <FormattedMessage id="quickActions.sort.creationdate" defaultMessage="Creation date" />,
		"value": "createdAt",
		"filters": {
			"ASC": "asc",
			"DESC": "desc"
		}
    },
	{
		"label": <FormattedMessage id="quickActions.sort.title" defaultMessage="Title" />,
		"value": "title",
		"filters": {
			"ASC": "asc",
			"DESC": "desc"
		}
	}
];