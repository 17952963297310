import {
    FETCH_TAGS,
    TOGGLE_TAGS_MODAL,
    SET_TRAIN_STATUS,
    ADD_TAG,
    UPDATE_TAG,
    ARCHIVE_TAG,
    SET_SMART,
    FLUSH_TAGS
} from '../../actions/tags';

const initialState = {
    tags: [],
    fetchStatus: "pending",
    fetchStatusForAgent: null,
    addStatus: null,
    updateStatus: null,
    archiveStatus: null,
    error: null,
    modal:{
        show: false,
        for: "ticket",
        initialTagsIds: [],
        sourceId: null
    },
    trainStatus: null
}

const tagsReducer = (state = initialState, action) => {
    switch(action.type) {

        case FLUSH_TAGS: 
            return {
                ...state,
                fetchStatus: "pending",
                fetchStatusForAgent: null,
                tags: [],
                error: null,
            }

        case FETCH_TAGS: 
            return {
                ...state,
                fetchStatus: action.status,
                fetchStatusForAgent: action.status,
                tags: action.status === "success" ? action.tags : null,
                error: action.status === "error" ? action.error : null,
            }

        case ADD_TAG:
            return {
                ...state,
                addStatus: action.status,
                tags: action.status === "success" ? [...state.tags, action.tag] : state.tags
            }
        
        case UPDATE_TAG:
            return {
                ...state,
                updateStatus: action.status,
                tags: action.status === "success" ? [
                    ...state.tags.map(elt => {
                        if(elt.id === action.tag.id){
                            return {
                                ...elt,
                                ...action.tag
                            }
                        } else {
                            return elt
                        }
                    })
                ] : state.tags
            }

        case ARCHIVE_TAG:
            return {
                ...state,
                archiveStatus: action?.status || state.archiveStatus,
                tags: [...state.tags.map(elt => {
                    if(elt.id === action.tagId){
                        return {
                            ...elt,
                            archived: action.archived
                        }
                    } else {
                        return elt
                    }
                })]
            }
        

        case SET_SMART:
            return {
                ...state,
                tags: [...state.tags.map(elt => {
                    if(elt.id === action.tagId){
                        return {
                            ...elt,
                            isSmart: true
                        }
                    } else {
                        return elt
                    }
                })]
            }

        case TOGGLE_TAGS_MODAL:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    show: !state.modal.show,
                    for: action.for || null,
                    initialTagsIds: action?.initialTagsIds || [],
                    sourceId: action?.sourceId || null
                }
            }

        case SET_TRAIN_STATUS:
            return {
                ...state,
                trainStatus: action.status
            }

        default: 
            return state;
    }
}
export default tagsReducer;