export const FETCH_COLLECTED_DATAS = "FETCH_COLLECTED_DATAS";

export const getCollectedDatas = (companyId) => {
    return (dispatch, getState, {rcsdk, rcRPA}) => {
        dispatch({type: FETCH_COLLECTED_DATAS, status: "pending"})
        return rcRPA.getCollectedDatas(companyId).then(resp => {
            
            dispatch({
                type: FETCH_COLLECTED_DATAS,
                status: "success",
                collectedDatas: Object.entries({
                    "global": {
                        collected: {
                            GDPRconsentSMS: "consent",
                            GDPRconsentRec: "consent",
                            rcsubject: "text"
                        },
                        name: "Global"
                    },
                    ...resp
                }).map(([agentId, collectedData]) => {
                    return {
                        agentId: agentId,
                        ...collectedData
                    }
                })
            })
            return resp
        }).catch(err => {
            dispatch({type: FETCH_COLLECTED_DATAS, status: "error", err: err})
        });
    }
}