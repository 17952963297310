import React from "react"
import { FormattedMessage } from "react-intl";
import reecallToaster from "../../../components/reecall/toaster";
import { v4 as uuid } from 'uuid';
import axios from "axios";
import { toast } from "react-toastify";
import { RPA_URL, BILLING_URL, EXPORT_URL } from "../../../configs/constants";



export const FETCH_COMPANY_PENDING = 'FETCH_COMPANY_PENDING';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const SET_COMPANY_REQUEST_STATUS = "SET_COMPANY_REQUEST_STATUS";

export const FETCH_COMPANY_SETTINGS = 'FETCH_COMPANY_SETTINGS';
export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const FLUSH_UPDATE_COMPANY_SETTINGS = 'FLUSH_UPDATE_COMPANY_SETTINGS';
export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const FLUSH_COMPANY = 'FLUSH_COMPANY';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const FLUSH_CREATE_COMPANY = 'FLUSH_CREATE_COMPANY';
export const FETCH_ALL_SETTINGS = 'FETCH_ALL_SETTINGS';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const FLUSH_COMPANY_DELETION = 'FLUSH_COMPANY_DELETION';
export const FLUSH_COMPANY_SETTINGS_STATUS = 'FLUSH_COMPANY_SETTINGS_STATUS';
export const FLUSH_UPDATE_STATUS = 'FLUSH_UPDATE_STATUS';
export const EXPORT_COMPANY_DATA = 'EXPORT_COMPANY_DATA';
export const FLUSH_EXPORT_DATA = 'FLUSH_EXPORT_DATA';

export const SET_COMPANY_SETTINGS_TAB = 'SET_COMPANY_SETTINGS_TAB';

export const FETCH_STRIPE_CUSTOMERS = 'FETCH_STRIPE_CUSTOMERS';


function fetchCompanyPending() {
    return {
        type: FETCH_COMPANY_PENDING
    }
}

export const setCompanyRequestStatus = (requestStatus) => ({
    type: SET_COMPANY_REQUEST_STATUS,
    requestStatus,
});

export function fetchCompanySuccess(company) {
    return {
        type: FETCH_COMPANY_SUCCESS,
        company: company
    }
}

function fetchCompanyError(error) {
    return {
        type: FETCH_COMPANY_ERROR,
        error: error
    }
}

export const setCompany = (company) => {
    return (dispatch, getStat, { rcsdk, rcAlgolia }) => {
        return dispatch(fetchCompanySuccess(company));
    }
}

export function fetchCompany(companyId) {
    return (dispatch, getState, { rcsdk, rcRPA, rcAlgolia }) => {
        dispatch({ type: FETCH_COMPANY, status: "pending" })
        return (
            rcsdk
                .getCompany(companyId)
                .include([
                    { relation: "members" },
                    {
                        relation: "companySetting",
                        scope: {
                            fields: ["value"],
                        },
                    }
                ])
                .then(response => {
                    rcRPA.setCompanyId(companyId)
                    dispatch(
                        {
                            type: FETCH_COMPANY,
                            company: response,
                            status: "success",
                        }
                    )
                })
                .catch(err => dispatch({ type: FETCH_COMPANY, status: "error", error: err }))
        )
    }
};

export const updateCompanySettings = (value, noToast) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        return rcsdk.updateCompanySetting(getState().company.companySettings.id, { ...getState().company.companySettings.value, ...value }).then(response => {
            noToast ?
                dispatch({
                    type: UPDATE_COMPANY_SETTINGS,
                    companySettings: response,
                })
                :
                dispatch({
                    type: UPDATE_COMPANY_SETTINGS,
                    companySettings: response,
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="companySettings.toast.update" defaultMessage="Settings updated successfully" />
                    }
                })
        }).catch(err => {
            dispatch(fetchCompanyError(err.message));
        })
    }
}

export const fetchCompanySettings = (companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch(fetchCompanyPending());
        return rcsdk.getOrCreateCompanySetting(companyId || getState()?.company?.company?.id, { "conversationSummaryLayout": [] })
            .then(response => {
                dispatch({
                    type: FETCH_COMPANY_SETTINGS,
                    companySettings: response
                });
                return response;
            }).catch(err => {
                dispatch(fetchCompanyError(err.message));
                return "error";
            })
    }
}

export const fetchAllCompanySettings = (where) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_ALL_SETTINGS, status: "pending" });
        return rcsdk.getCompanySettings().where(where).then(response => {
            dispatch({
                type: FETCH_ALL_SETTINGS,
                status: "success",
                settings: response
            });
        }).catch(err => {
            dispatch({ type: FETCH_ALL_SETTINGS, status: "error", error: err.message });
        })
    }
}


export const updateCompanyLogo = (id, data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_COMPANY_LOGO, status: "pending" });
        return rcsdk
            .updateCompany(id, data)
            .then(response => {
                dispatch({
                    type: UPDATE_COMPANY_LOGO,
                    company: response,
                    status: "success",
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="companyLogo.toast.update" defaultMessage="Company logo updated successfully" />
                    }
                });
            }).catch(err => {
                dispatch(fetchCompanyError({ type: UPDATE_COMPANY_LOGO, status: "error", error: err.message }));
            })
    }
}

export const updateCompany = (id, data) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: UPDATE_COMPANY, status: "pending" });
        return rcsdk
            .updateCompany(id, data)
            .then(response => {
                dispatch({
                    type: UPDATE_COMPANY,
                    company: response,
                    status: "success",
                    successToast: {
                        type: "UPDATE",
                        message: <FormattedMessage id="company.toast.update" defaultMessage="Company updated successfully" />
                    }
                });
            }).catch(err => {
                dispatch(fetchCompanyError({ type: UPDATE_COMPANY, status: "error", error: err.message }));
            })
    }
}

export const createCompany = (data, withAgentAndChannel, gdprSentences) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {

        if (!withAgentAndChannel) {
            dispatch({ type: CREATE_COMPANY, step: "company", status: "pending" })
            return rcsdk
                .createCompany(data)
                .then(company => {
                    rcsdk
                        .getOrCreateCompanySetting(company.id, { "csmOverride": false, "validatedAccess": false })
                        .then(() => {
                            dispatch({ type: CREATE_COMPANY, step: "company", status: "success", company: company })
                            reecallToaster("success");
                        }).catch(err => console.log(err))
                }).catch(err => {
                    reecallToaster("error");
                })
        } else {
            dispatch({ type: CREATE_COMPANY, step: "company", status: "pending" })

            return rcsdk
                .createCompany(data)
                .then(company => {

                    dispatch({ type: CREATE_COMPANY, step: "company", status: "success" })

                    const agentData = {
                        name: company?.name,
                        rank: "junior",
                        companyId: company.id,
                        published: true,
                        config: {
                            speak: "fr-FR",
                            closeConversation: true,
                            noFaq: false,
                            noBasic: false,
                            misunderstandLoop: 1,
                            connections: [],
                            activeSkills: [],
                            skills: {},
                            toCollect: {
                                reason: {
                                    question: "Pour quelle raison nous contactez vous ?",
                                },
                                identity: {
                                    question:
                                        "Pouvez-vous me communiquer vos noms et prénoms s'il vous plaît ?",
                                },
                                date: {
                                    question:
                                        "A quel moment seriez vous disponible pour être recontacté ?",
                                },
                            },
                            rules: {
                                "<light>": ["<text>|reason <text>|identity <text>|date"],
                            },
                            toPlay: {
                                join: {},
                                start: {},
                                end: {},
                            },
                            onStart: { intent: "light" },
                        },
                    }

                    dispatch({ type: CREATE_COMPANY, step: "agent", status: "pending" })

                    return rcsdk.createAgent(agentData).then(async (agent) => {

                        dispatch({ type: CREATE_COMPANY, step: "agent", status: "success" })
                        dispatch({ type: CREATE_COMPANY, step: "channels", status: "pending" })

                        let formattedChannel = {
                            data: {
                                "live": true,
                                "escalation": true
                            },
                            agentId: agent.id,
                            companyId: company.id,
                            gdprSentences: gdprSentences,
                        };

                        const types = [
                            { name: "Phone", type: "phone", tokenHelpSelector: "phone-token" },
                            { name: "Reecall Chat", type: "chat", tokenHelpSelector: "chat-token" }
                        ]

                        const getPhoneToken = async () => {
                            return axios({
                                method: 'post',
                                url: `${RPA_URL}/phonemanagement/buy`,
                                headers: { "x-reecall-token": "0d4085d3-c2ce-4d2f-a11c-cc7b4587d339" },
                                data: { "companyname": company?.name }
                            }).then((response) => {
                                return response.data.number
                            }).catch(err => {
                                console.log(err)
                                toast.error(`Unable to get phone token: ${err}. Channel creation unavailable.`)
                                return "no-token"
                            })
                        }

                        let channelsPromises = [];
                        for (let index = 0; index < types.length; index++) {
                            const el = types[index];
                            const token = el.type === "phone" ? await getPhoneToken() : uuid()
                            let channelPromise = token !== "no-token" ? rcsdk.createChannel({ ...formattedChannel, ...el, token: token }) : token
                            channelsPromises.push(channelPromise);
                        }

                        await Promise.all([...channelsPromises]).then(async () => {
                            dispatch({
                                type: CREATE_COMPANY,
                                step: "channels",
                                status: channelsPromises.includes('no-token') ? "error" : "success",
                                target: channelsPromises.includes('no-token') ? "phone" : null
                            })
                            dispatch({ type: CREATE_COMPANY, step: "hooks", status: "pending" })
                            return axios({
                                method: 'post',
                                url: `${RPA_URL}/hooks/reecall`,
                                data: { companyId: company.id }
                            }).then(() => {
                                rcsdk
                                    .getOrCreateCompanySetting(company.id, { "csmOverride": false, "validatedAccess": false })
                                    .then(() => {
                                        dispatch({ type: CREATE_COMPANY, step: "hooks", status: "success", company: company, creationCompleted: true })
                                        reecallToaster("success");
                                    }).catch(err => console.log(err))
                            }).catch(err => console.log(err))
                        }).catch(err => console.log(err))
                    })

                })
                .catch(err => {
                    reecallToaster("error");
                })
        }
    }
}

export const deleteCompany = (companyId, agents, channels) => {
    return async (dispatch, getState, { rcsdk, rcAlgolia }) => {

        dispatch({ type: DELETE_COMPANY, status: "pending" });

        let deleteChannelsPromises = [];
        for (let index = 0; index < channels.length; index++) {
            const channel = channels[index];
            if (channel.companyId === companyId) {
                let deleteChannelPromise = rcsdk.deleteChannel(channel.id)
                deleteChannelsPromises.push(deleteChannelPromise);
            }
        }

        await Promise.all([...deleteChannelsPromises]).then(async () => {

            let deleteAgentsPromises = [];
            for (let index = 0; index < agents.length; index++) {
                const agent = agents[index];
                if (agent.companyId === companyId) {
                    let deleteAgentPromise = rcsdk.deleteAgent(agent.id)
                    deleteAgentsPromises.push(deleteAgentPromise);
                }
            }

            await Promise.all([...deleteAgentsPromises]).then(async () => {
                return axios({
                    method: 'post',
                    url: `${RPA_URL}/hooks/remove/reecall`,
                    data: { companyId: companyId }
                }).then(() => {
                    return rcsdk
                        .deleteCompany(companyId)
                        .then(response => {
                            dispatch({
                                type: DELETE_COMPANY,
                                company: response,
                                status: "success",
                                successToast: {
                                    type: "UPDATE",
                                    message: <FormattedMessage id="company.toast.delete" defaultMessage="Company deleted successfully" />
                                }
                            });
                        }).catch(err => {
                            dispatch(fetchCompanyError({ type: DELETE_COMPANY, status: "error", error: err.message }));
                        })
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))


        }).catch(err => console.log(err))
    }
}


export const exportCompanyData = (data, companyId) => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: EXPORT_COMPANY_DATA, status: "pending" });
        return axios({
            method: 'post',
            // url: `https://am-export.eu.ngrok.io/export/${companyId}`,
            url: `${EXPORT_URL}/export/${companyId}`,
            data
        }).then(response => {
            dispatch({
                type: EXPORT_COMPANY_DATA,
                status: "success",
                settings: response,
                successToast: {
                    type: "EXPORT",
                    message: `Data successfully requested. ${response.data.message}`
                }
            });

        }).catch(err => {
            dispatch({ type: EXPORT_COMPANY_DATA, status: "error", error: err.message });
            toast.error(`Unable to request export: ${err}`)
        })
    }
}

export const fetchStripeCustomers = () => {
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_STRIPE_CUSTOMERS, status: "pending" });
        return axios({
            method: 'get',
            url: `${BILLING_URL}/stripe/customers`,
            // url: `https://am-billing.eu.ngrok.io/stripe/customers`,
        }).then(response => {
            dispatch({
                type: FETCH_STRIPE_CUSTOMERS,
                status: "success",
                stripeCustomers: response.data.customers,
            });
        }).catch(err => {
            dispatch({ type: FETCH_STRIPE_CUSTOMERS, status: "error", error: err.message });
            toast.error(`Unable to get Stripe customer's list. Please retry later`)
        })
    }
}