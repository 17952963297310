import axios from 'axios';
import { RPA_URL } from '../../../configs/constants';

export const FETCH_LOGS = 'FETCH_LOGS';

export const fetchLogs = (companyId, processId) => {
    const query = processId ? `?process_id=${processId}` : companyId ? `?company_id=${companyId}` : ""
    return (dispatch, getState, { rcsdk, rcAlgolia }) => {
        dispatch({ type: FETCH_LOGS, status: "pending" });
        return Promise.all([
            axios.get(`${RPA_URL}/audits${query}`),
        ]).then((res) => {
            dispatch({ type: FETCH_LOGS, logs: res[0].data, status: "success" });
        }).catch(err => dispatch({ type: FETCH_LOGS, error: err, status: "error" }));

    }
}